
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'UIIconInCircle',
  props: {
    icon: {
      type: String,
      default: 'close',
    },
  },
});
