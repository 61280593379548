import { useContext } from '@nuxtjs/composition-api';
import { isCdnAsset } from '~/helpers/isCdnAsset';

export const getOptimizedImage = (src, modifiers = {}) => {
  if (!src) return;

  const { $img } = useContext();
  const provider = isCdnAsset(src) ? 'imgproxy' : 'ipx';

  // eslint-disable-next-line consistent-return,func-names
  return $img(src, modifiers, { provider });
};
