
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { useMagicKeys, onClickOutside } from '@vueuse/core';

export default defineComponent({
  props: {
    width: {
      type: String,
      default: '700px',
    },
    closeable: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { emit }) {
    const { escape } = useMagicKeys();
    const isOpen = ref(false);

    const close = () => {
      if (isOpen.value === false) return;
      isOpen.value = false;
      emit('closed');
    };

    const open = () => {
      if (isOpen.value === true) return;
      isOpen.value = true;
    };

    watch(escape, (v) => {
      if (!v) return;
      if (isOpen.value === false) return;
      close();
    });

    const modal = ref(null);
    onClickOutside(modal, () => close());

    return {
      close,
      open,
      modal,
      isOpen,
    };
  },
});
