
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { getOptimizedImage } from '~/helpers/getOptimizedImage';

export default defineComponent({
  name: 'HelpAnyQuestion',
  props: {
    image: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: Object,
    },
    backgroundImage: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    modal: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const modalRef = ref();

    const openModal = () => {
      modalRef.value.open();
    };

    const isImageExist = computed(
      () =>
        (props.backgroundImage && props.backgroundImage.src) ||
        (props.image && props.image.src)
    );

    return {
      modalRef,
      getOptimizedImage,
      isImageExist,
      openModal,
    };
  },
});
